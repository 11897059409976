import styled from "styled-components";

export const BadgeWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: none;
  background: #1ca9e5;
  color: #ffffff;
  font-size: 9px;
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
`;
